import FeatureImg02 from '../assets/images/feature-image-02.png'
import FeatureImg03 from '../assets/images/feature-image-03.png'
import {createEffect, For} from "solid-js";
import AOS from "aos";
import Logo from "../assets/olion-text-logo.svg";
import B2BProductsGrid from "../assets/images/b2b-products-grid.png";
import B2BPerfGraph from "../assets/images/b2b-perf-graph.png";
import {setJoinWaitlistModalOpen} from "./modals/JoinWaitlistModal";
import {openCalendlyPopup} from "./modals/CalendlyPopup";

export default function B2BMarketplaceFeatures() {
    let highlighterRef;
    let canvasRef;
    createEffect(() => {
        const options = {
            quantity: canvasRef.dataset.particleQuantity,
            staticity: canvasRef.dataset.particleStaticity,
            ease: canvasRef.dataset.particleEase,
        };
        new ParticleAnimation(canvasRef, options);
        new Highlighter(highlighterRef);
         // AOS.init({
        //     once: true,
        //     disable: 'phone',
        //     duration: 1000,
        //     easing: 'ease-out-cubic',
        // })
    })

    const additionalFeatures = [
        {
            title: 'Store Profile',
            subtitle: 'Change your branding so that it suits you and view other store profiles and listings',
            icon: '<path d="M4.297 4h15.449A2.252 2.252 0 0 1 22 6.249v11.502A2.249 2.249 0 0 1 19.75 20H4.25A2.25 2.25 0 0 1 2 17.751v-7.207c.063.074.133.141.21.2a1.384 1.384 0 0 0 1.29.143v6.861a.75.75 0 0 0 .75.75h2.749v-2.753a1.749 1.749 0 0 1 1.607-1.743l.143-.006h6.495a1.75 1.75 0 0 1 1.744 1.606l.006.143v2.749h2.752a.75.75 0 0 0 .75-.75V6.249a.75.75 0 0 0-.75-.75H5.418l-.281-.09a.891.891 0 0 1-.564-.56L4.297 4Zm4.197 14.494h7l-.005-2.75a.25.25 0 0 0-.193-.242l-.057-.007H8.744a.25.25 0 0 0-.243.193l-.007.057v2.749Zm5.627-10.616a2.998 2.998 0 0 1-3.27 4.89A3.002 3.002 0 0 1 9 9.999A2.998 2.998 0 0 1 12 7a3 3 0 0 1 2.121.878Zm-3.182 1.06a1.499 1.499 0 1 0 2.12 2.12a1.499 1.499 0 0 0-2.12-2.12Zm-8.578-.1l.3.917a.363.363 0 0 0 .676.023l.008-.023l.3-.916a1.888 1.888 0 0 1 1.199-1.197l.918-.3a.363.363 0 0 0 0-.684h-.018l-.919-.3a1.882 1.882 0 0 1-1.198-1.197l-.3-.918a.364.364 0 0 0-.552-.175a.364.364 0 0 0-.132.175l-.3.917l-.007.023a1.892 1.892 0 0 1-1.173 1.175l-.919.3a.364.364 0 0 0-.175.55a.36.36 0 0 0 .175.134l.919.3a1.9 1.9 0 0 1 1.198 1.197Zm2.53 1.71l.536-.175a1.118 1.118 0 0 0 .587-.472c.041-.071.075-.147.1-.226l.174-.536a.217.217 0 0 1 .12-.124a.21.21 0 0 1 .202.024a.222.222 0 0 1 .077.102l.174.536a1.099 1.099 0 0 0 .7.698l.535.174h.01a.213.213 0 0 1 .141.2a.211.211 0 0 1-.139.199l-.535.174a1.1 1.1 0 0 0-.7.699l-.173.534a.22.22 0 0 1-.078.1a.212.212 0 0 1-.321-.1l-.174-.534a1.1 1.1 0 0 0-.7-.702l-.535-.174a.213.213 0 0 1-.1-.32a.2.2 0 0 1 .1-.078Z"/>'
        },
        {
            title: 'Memos & Consignments',
            subtitle: 'Ask for a memo or consignment for any listing, and respond to requests from other stores',
            icon: '<path d="M9 5.5a3.5 3.5 0 0 1-3 3.465V15a3 3 0 0 0 3 3h2.69l-.97-.97a.75.75 0 1 1 1.06-1.06l2.25 2.25a.75.75 0 0 1 0 1.06l-2.25 2.25a.75.75 0 1 1-1.06-1.06l.97-.97H9A4.5 4.5 0 0 1 4.5 15V8.855A3.502 3.502 0 0 1 5.5 2A3.5 3.5 0 0 1 9 5.5Zm-1.5 0a2 2 0 1 0-4 0a2 2 0 0 0 4 0Zm14.5 13a3.5 3.5 0 1 1-4-3.465V9a3 3 0 0 0-3-3h-1.94l.97.97a.75.75 0 0 1-1.06 1.06l-2.25-2.25a.75.75 0 0 1 0-1.06l2.25-2.25a.75.75 0 1 1 1.06 1.06l-.97.97H15A4.5 4.5 0 0 1 19.5 9v6.145c1.446.43 2.5 1.77 2.5 3.355Zm-1.5 0a2 2 0 1 0-4 0a2 2 0 0 0 4 0Z"/>'
        },
        {
            title: 'Price Lists',
            subtitle: 'Create custom price lists depending on the store viewing your listings and other criteria',
            icon: '<path d="M11 5.5a3.5 3.5 0 0 1-3 3.465V11.5h6.25a2.25 2.25 0 0 0 2.25-2.25v-.395A3.502 3.502 0 0 1 17.5 2a3.5 3.5 0 0 1 .5 6.965v.285A3.75 3.75 0 0 1 14.25 13H8v2.035a3.5 3.5 0 1 1-1.5.11v-6.29A3.502 3.502 0 0 1 7.5 2A3.5 3.5 0 0 1 11 5.5Zm-3.5 2a2 2 0 1 0 0-4a2 2 0 0 0 0 4Zm10 0a2 2 0 1 0 0-4a2 2 0 0 0 0 4Zm-8 11a2 2 0 1 0-4 0a2 2 0 0 0 4 0Z"/>'
        },
        {
            title: 'Seasonal Events',
            subtitle: 'View News, Updates and Events created from the Olion team, then create special offers and discounts depending on the event',
            icon: '<path d="M17.75 3A3.25 3.25 0 0 1 21 6.25v11.5A3.25 3.25 0 0 1 17.75 21H6.25A3.25 3.25 0 0 1 3 17.75V6.25A3.25 3.25 0 0 1 6.25 3h11.5Zm1.75 5.5h-15v9.25c0 .966.784 1.75 1.75 1.75h11.5a1.75 1.75 0 0 0 1.75-1.75V8.5Zm-11.75 6a1.25 1.25 0 1 1 0 2.5a1.25 1.25 0 0 1 0-2.5Zm4.25 0a1.25 1.25 0 1 1 0 2.5a1.25 1.25 0 0 1 0-2.5Zm-4.25-4a1.25 1.25 0 1 1 0 2.5a1.25 1.25 0 0 1 0-2.5Zm4.25 0a1.25 1.25 0 1 1 0 2.5a1.25 1.25 0 0 1 0-2.5Zm4.25 0a1.25 1.25 0 1 1 0 2.5a1.25 1.25 0 0 1 0-2.5Zm1.5-6H6.25A1.75 1.75 0 0 0 4.5 6.25V7h15v-.75a1.75 1.75 0 0 0-1.75-1.75Z"/>'
        },
        {
            title: 'Communication',
            subtitle: 'Message other stores, view the order and listing in the same place, and create special private offers, recommendation and shipping deals',
            icon: '<path d="M12 4.5a8.5 8.5 0 0 0-6.016 14.505a.75.75 0 0 1-1.061 1.06A9.971 9.971 0 0 1 2 13C2 7.477 6.477 3 12 3s10 4.477 10 10a9.971 9.971 0 0 1-2.923 7.065a.75.75 0 0 1-1.061-1.06A8.5 8.5 0 0 0 12 4.5ZM12 8a5 5 0 0 0-3.534 8.537a.75.75 0 0 1-1.06 1.061a6.5 6.5 0 1 1 9.188 0a.75.75 0 0 1-1.06-1.06A5 5 0 0 0 12 8Zm0 2.5a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5ZM11 13a1 1 0 1 1 2 0a1 1 0 0 1-2 0Z"/>'
        },
        {
            title: 'Integration',
            subtitle: 'Receive notifications, and view your own Marketplace listings directly from your Admin Panel',
            icon: '<path d="M21.78 3.28a.75.75 0 0 0-1.06-1.06l-2.012 2.012a4.251 4.251 0 0 0-5.463.462l-1.068 1.069a1.75 1.75 0 0 0 0 2.474l3.585 3.586a1.75 1.75 0 0 0 2.475 0l1.068-1.068a4.251 4.251 0 0 0 .463-5.463L21.78 3.28Zm-3.585 2.475l.022.023l.003.002l.002.003l.023.022a2.75 2.75 0 0 1 0 3.89l-1.068 1.067a.25.25 0 0 1-.354 0l-3.586-3.585a.25.25 0 0 1 0-.354l1.068-1.068a2.75 2.75 0 0 1 3.89 0ZM10.78 11.28a.75.75 0 1 0-1.06-1.06L8 11.94l-.47-.47a.75.75 0 0 0-1.06 0l-1.775 1.775a4.251 4.251 0 0 0-.463 5.463L2.22 20.72a.75.75 0 1 0 1.06 1.06l2.012-2.012a4.251 4.251 0 0 0 5.463-.463l1.775-1.775a.75.75 0 0 0 0-1.06l-.47-.47l1.72-1.72a.75.75 0 1 0-1.06-1.06L11 14.94L9.06 13l1.72-1.72Zm-3.314 2.247l.004.003l.003.004l2.993 2.993l.004.003l.003.004l.466.466l-1.244 1.245a2.75 2.75 0 0 1-3.89 0l-.05-.05a2.75 2.75 0 0 1 0-3.89L7 13.062l.466.466Z"/>'
        },
    ]

    return (
        <section className="relative">

             {/* Particles animation*/}
            <div className="absolute left-1/2 -translate-x-1/2 top-0 -z-10 w-80 h-56 -ml-32">
                <div className="absolute inset-0 -z-10" aria-hidden="true">
                    <canvas ref={canvasRef} data-particle-quantity="6" data-particle-staticity="30"></canvas>
                </div>
            </div>

            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pt-16 md:pt-32">

                     {/* Section header*/}
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                        <h2 id="b2b-marketplace" className="h2 font-normal bg-clip-text text-transparent bg-gradient-to-r flex flex-row justify-center from-slate-200/60 via-slate-200 to-slate-200/60 pb-4">
                            <img src={Logo} className="w-36 h-20 md:w-48 -mt-[14px] md:h-24 md:-mt-[16px] object-cover" alt="Olion"/>
                            marketplace
                        </h2>
                        <p className="text-lg text-slate-400">
                            Join our exclusive, world`s first B2B Marketplace for Jewelry & Diamonds, add your listings and start selling other stores listings as your own directly to your customers
                        </p>
                    </div>

                     {/* Highlighted boxes*/}
                    <div className="relative pb-12 md:pb-20">
                         {/* Blurred shape*/}
                        <div
                            className="absolute bottom-0 -mb-20 left-1/2 -translate-x-1/2 blur-2xl opacity-50 pointer-events-none"
                            aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="434" height="427">
                                <defs>
                                    <linearGradient id="bs2-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
                                        <stop offset="0%" stop-color="#ffbf00"/>
                                        <stop offset="100%" stop-color="#ffbf00" stop-opacity="0"/>
                                    </linearGradient>
                                </defs>
                                <path fill="url(#bs2-a)" fill-rule="evenodd" d="m346 898 461 369-284 58z"
                                      transform="translate(-346 -898)"/>
                            </svg>
                        </div>
                         {/* Grid*/}
                        <div className="grid md:grid-cols-12 gap-6 group" ref={highlighterRef}>
                             {/* Box #1*/}
                            <div className="md:col-span-12" data-aos="fade-down">
                                <div
                                    className="relative h-full bg-slate-800 rounded-3xl p-px before:absolute before:w-96 before:h-96 before:-left-48 before:-top-48 before:bg-gold-500 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-20 before:z-30 before:blur-[100px] after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.slate.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden">
                                    <div
                                        className="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                                        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                                             {/* Blurred shape*/}
                                            <div className="absolute right-0 top-0 blur-2xl" aria-hidden="true">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="342" height="393">
                                                    <defs>
                                                        <linearGradient id="bs-a" x1="19.609%" x2="50%" y1="14.544%"
                                                                        y2="100%">
                                                            <stop offset="0%" stop-color="#ffbf00"/>
                                                            <stop offset="100%" stop-color="#ffbf00" stop-opacity="0"/>
                                                        </linearGradient>
                                                    </defs>
                                                    <path fill="url(#bs-a)" fill-rule="evenodd"
                                                          d="m104 .827 461 369-284 58z"
                                                          transform="translate(0 -112.827)" opacity=".7"/>
                                                </svg>
                                            </div>
                                             {/* Radial gradient*/}
                                            <div
                                                className="absolute flex items-center justify-center bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 h-full aspect-square"
                                                aria-hidden="true">
                                                <div
                                                    className="absolute inset-0 translate-z-0 bg-gold-500 rounded-full blur-[120px] opacity-70"></div>
                                                <div
                                                    className="absolute w-1/4 h-1/4 translate-z-0 bg-gold-400 rounded-full blur-[40px]"></div>
                                            </div>


                                             {/* Text*/}
                                            <div
                                                className="md:max-w-[480px] shrink-0 order-1 md:order-none p-6 pt-0 md:p-8 md:pr-0">
                                                <div className="mb-5">
                                                    <div>
                                                        <h3 className="inline-flex text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-1">
                                                            Browse & Sell
                                                        </h3>
                                                        <p className="text-slate-400">
                                                            Compare prices, search for specific one-of-a-kind<br/> jewelry & diamonds, then inquire if you can sell them on your website
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <a className="btn-sm cursor-pointer text-slate-300 hover:text-white transition duration-150 ease-in-out group [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-full before:pointer-events-none"
                                                       onClick={() => openCalendlyPopup()}>
                                                            <span className="relative inline-flex items-center">
                                                                Get Started <span
                                                                className="tracking-normal text-gold-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                                                            </span>
                                                    </a>
                                                    <div className="font-medium bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pt-4">
                                                        Join today and get exclusive deals!
                                                    </div>
                                                </div>
                                            </div>
                                             {/* Image*/}
                                            <div className="relative w-full h-64 md:h-auto overflow-hidden">
                                                <img className="absolute bottom-0 left-1/2 -translate-x-1/2 mx-auto max-w-none md:h-[400px] md:object-fill md:mr-0 md:relative md:left-0 md:translate-x-0"
                                                     src={B2BProductsGrid} width="504" height="400" alt="Feature 01"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             {/* Box #2*/}
                            <div className="md:col-span-5" data-aos="fade-down">
                                <div
                                    className="relative h-full bg-slate-800 rounded-3xl p-px before:absolute before:w-96 before:h-96 before:-left-48 before:-top-48 before:bg-gold-500 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-20 before:z-30 before:blur-[100px] after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.slate.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden">
                                    <div
                                        className="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                                        <div className="flex flex-col">
                                             {/* Radial gradient*/}
                                            <div
                                                className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                                                aria-hidden="true">
                                                <div
                                                    className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                                            </div>
                                             {/* Text*/}
                                            <div
                                                className="md:max-w-[480px] shrink-0 order-1 md:order-none p-6 pt-0 md:p-8">
                                                <div>
                                                    <h3 className="inline-flex text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-1">
                                                        List your own
                                                    </h3>
                                                    <p className="text-slate-400">
                                                        Decide what products you want to list, at what price, and to whom
                                                    </p>
                                                </div>
                                            </div>
                                             {/* Image*/}
                                            <div className="relative w-full h-64 md:h-auto overflow-hidden md:pb-8">
                                                <img
                                                    className="absolute bottom-0 left-1/2 -translate-x-1/2 mx-auto max-w-none md:max-w-full md:relative md:left-0 md:translate-x-0"
                                                    src={FeatureImg03} width="230" height="230"
                                                    alt="Feature 03"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             {/* Box #3*/}
                            <div className="md:col-span-7" data-aos="fade-down">
                                <div
                                    className="relative h-full bg-slate-800 rounded-3xl p-px before:absolute before:w-96 before:h-96 before:-left-48 before:-top-48 before:bg-gold-500 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-20 before:z-30 before:blur-[100px] after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.slate.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden">
                                    <div
                                        className="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                                        <div className="flex flex-col">
                                             {/* Radial gradient*/}
                                            <div
                                                className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                                                aria-hidden="true">
                                                <div
                                                    className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                                            </div>
                                             {/* Text*/}
                                            <div
                                                className="md:max-w-[480px] shrink-0 order-1 md:order-none p-6 pt-0 md:p-8">
                                                <div>
                                                    <h3 className="inline-flex text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-1">
                                                        Profit
                                                    </h3>
                                                    <p className="text-slate-400">
                                                        View actionable insights & performance metrics to help you make better decisions
                                                    </p>
                                                </div>
                                            </div>
                                             {/* Image*/}
                                            <div className="relative w-full h-64 md:h-auto overflow-hidden md:pb-8">
                                                <img
                                                    className="absolute bottom-0 left-1/2 -translate-x-1/2 mx-auto max-w-none md:max-w-full md:w-auto md:relative md:left-0 md:translate-x-0"
                                                    src={B2BPerfGraph} width="536" height="230"
                                                    alt="Feature 02"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>1
                            </div>
                        </div>
                    </div>

                     {/* Features list*/}
                    <div className="grid md:grid-cols-3 gap-8 md:gap-12">
                         {/* Feature*/}
                        <For each={additionalFeatures}>
                            {(feature) => (
                                <div>
                                    <div className="flex items-center space-x-2 mb-1">
                                        <svg className="shrink-0 fill-slate-300" xmlns="http://www.w3.org/2000/svg" width="24" height="24" innerHTML={feature.icon}/>
                                        <h4 className="font-medium text-slate-50">
                                            {feature.title}
                                        </h4>
                                    </div>
                                    <p className="text-sm text-slate-400">
                                        {feature.subtitle}
                                    </p>
                                </div>
                            )}
                        </For>
                    </div>
                </div>
            </div>
        </section>
    );
}
