import {createEffect} from "solid-js";
import AOS from "aos";
import {setJoinWaitlistModalOpen} from "./modals/JoinWaitlistModal";
import {openCalendlyPopup} from "./modals/CalendlyPopup";

export default function CTA() {
    createEffect(() => {
         // AOS.init({
        //     once: true,
        //     disable: 'phone',
        //     duration: 1000,
        //     easing: 'ease-out-cubic',
        // })
    })
    return (
        <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="relative px-8 py-12 md:py-20 rounded-[3rem] overflow-hidden">
                     {/* Radial gradient*/}
                    <div
                        className="absolute flex items-center justify-center top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/3 aspect-square"
                        aria-hidden="true">
                        <div
                            className="absolute inset-0 translate-z-0 bg-gold-500 rounded-full blur-[120px] opacity-70"></div>
                        <div
                            className="absolute w-1/4 h-1/4 translate-z-0 bg-gold-400 rounded-full blur-[40px]"></div>
                    </div>
                     {/* Blurred shape*/}
                    <div
                        className="absolute bottom-0 translate-y-1/2 left-0 blur-2xl opacity-50 pointer-events-none -z-10"
                        aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="434" height="427">
                            <defs>
                                <linearGradient id="bs5-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
                                    <stop offset="0%" stop-color="#ffbf00"/>
                                    <stop offset="100%" stop-color="#bf9b30" stop-opacity="0"/>
                                </linearGradient>
                            </defs>
                            <path fill="url(#bs5-a)" fill-rule="evenodd" d="m0 0 461 369-284 58z"
                                  transform="matrix(1 0 0 -1 0 427)"/>
                        </svg>
                    </div>
                     {/* Content*/}
                    <div className="max-w-3xl mx-auto text-center">
                        <div>
                            <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-gold-500 to-gold-200 pb-3">
                                optimize your cash flow
                            </div>
                        </div>
                        <h2 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4">
                            Take control of your business
                        </h2>
                        <p className="text-lg text-slate-400 mb-8">
                            Currently Olion is available by invitation only. Join the Waitlist and be amongst the first to try our brand new platform. You can also join to be notified when we launch
                        </p>
                        <div>
                            <a onClick={() => openCalendlyPopup()} className="btn cursor-pointer text-slate-900 bg-gradient-to-r from-white/80 via-white to-white/80 hover:bg-white transition duration-150 ease-in-out group" href="#0">
                                Get Started
                                <span className="tracking-normal text-gold-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                            </a>
                            <div className="font-medium bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pt-4">
                                Join today and get exclusive deals!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
